define("shared/components/k3s-node-args/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JW8NtH72",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\",true],[10,\"class\",\"acc-label\"],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"clusterDashboard.k3sInfo.nodeArgs.title\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"p\",true],[10,\"class\",\"help-block\"],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"clusterDashboard.k3sInfo.nodeArgs.detail\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[28,\"gte\",[[24,[\"node\",\"k3sNodeArgs\",\"length\"]],1],null]],null,{\"statements\":[[0,\"  \"],[7,\"pre\",true],[10,\"class\",\"bg-setting\"],[10,\"style\",\"font-size: 14px;\"],[8],[1,[24,[\"node\",\"k3sNodeArgs\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"mt-20 text-center\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"clusterDashboard.k3sInfo.nodeArgs.noArgs\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/k3s-node-args/template.hbs"
    }
  });

  _exports.default = _default;
});