enifed("ember-template-compiler/lib/plugins/assert-splattribute-expression", ["exports", "@ember/debug", "ember-template-compiler/lib/system/calculate-location-display"], function (_exports, _debug, _calculateLocationDisplay) {
  "use strict";

  _exports.default = assertSplattributeExpressions;

  function assertSplattributeExpressions(env) {
    var {
      moduleName
    } = env.meta;
    return {
      name: 'assert-splattribute-expressions',
      visitor: {
        PathExpression({
          original,
          loc
        }) {
          if (original === '...attributes') {
            true && !false && (0, _debug.assert)(errorMessage() + " " + (0, _calculateLocationDisplay.default)(moduleName, loc));
          }
        }

      }
    };
  }

  function errorMessage() {
    return '`...attributes` can only be used in the element position e.g. `<div ...attributes />`. It cannot be used as a path.';
  }
});