enifed("ember-template-compiler/tests/utils/transform-test-case", ["exports", "@glimmer/compiler", "internal-test-helpers", "ember-template-compiler/index"], function (_exports, _compiler, _internalTestHelpers, _index) {
  "use strict";

  _exports.default = void 0;

  class _default extends _internalTestHelpers.AbstractTestCase {
    assertTransformed(before, after) {
      this.assert.deepEqual(deloc(ast(before)), deloc(ast(after)));
    }

  }

  _exports.default = _default;

  function ast(template) {
    var program = null;

    function extractProgram() {
      return {
        name: 'extract-program',
        visitor: {
          Program: {
            exit(node) {
              program = clone(node);
            }

          }
        }
      };
    }

    var options = (0, _index.compileOptions)({
      moduleName: '-top-level'
    });
    options.plugins.ast.push(extractProgram);
    (0, _compiler.precompile)(template, options);
    return program;
  }

  function clone(node) {
    var out = Object.create(null);
    var keys = Object.keys(node);
    keys.forEach(key => {
      var value = node[key];

      if (value !== null && typeof value === 'object') {
        out[key] = clone(value);
      } else {
        out[key] = value;
      }
    });
    return out;
  }

  function deloc(node) {
    var out = Object.create(null);
    var keys = Object.keys(node);
    keys.forEach(key => {
      var value = node[key];

      if (key === 'loc') {
        return;
      } else if (value !== null && typeof value === 'object') {
        out[key] = deloc(value);
      } else {
        out[key] = value;
      }
    });
    return out;
  }
});