define("shared/components/share-member-row/component", ["exports", "ui/utils/errors", "shared/components/share-member-row/template", "identicon.js", "shared/utils/constants"], function (_exports, _errors, _template, _identicon, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Component.extend({
    globalStore: Ember.inject.service(),
    layout: _template.default,
    tagName: '',
    member: null,
    editing: true,
    isPublic: false,
    clusterResource: null,
    users: null,
    principal: null,
    principalId: null,
    principalGravatarSrc: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var isPublic = this.isPublic,
          member = this.member;

      if (!isPublic && (member.userPrincipalId || member.groupPrincipalId)) {
        var principalId = member.userPrincipalId || member.groupPrincipalId;
        this.globalStore.rawRequest({
          url: "principals/".concat(encodeURIComponent(principalId)),
          method: 'GET'
        }).then(function (xhr) {
          if (xhr.status === 204) {
            return;
          }

          if (xhr.body && _typeof(xhr.body) === 'object') {
            Ember.set(_this, 'principal', Ember.set(_this, 'external', xhr.body));

            _this.principalChanged();
          }

          return xhr;
        }).catch(function (xhr) {
          if (_this.isDestroyed || _this.isDestroying) {
            return;
          }

          if (member.userPrincipalId) {
            Ember.set(_this, 'principalId', member.userPrincipalId);
            Ember.set(_this, 'principalGravatarSrc', "data:image/png;base64,".concat(new _identicon.default(AWS.util.crypto.md5(member.userPrincipalId || 'Unknown', 'hex'), 80, 0.01).toString()));
          }

          return xhr;
        });
      }
    },
    actions: {
      gotError: function gotError(err) {
        Ember.set(this, 'errors', [_errors.default.stringify(err)]);
      },
      addAuthorized: function addAuthorized(principal) {
        if (principal) {
          var principalType = principal.principalType,
              id = principal.id;

          if (principalType === 'user') {
            Ember.set(this, 'member.userPrincipalId', id);
          } else if (principalType === 'group') {
            Ember.set(this, 'member.groupPrincipalId', id);
          }
        }
      },
      remove: function remove() {
        this.remove(this.member);
      }
    },
    choices: Ember.computed('C.CLUSTER_TEMPLATE_ROLES', function () {
      var roles = _constants.default.CLUSTER_TEMPLATE_ROLES;
      return Object.keys(roles).map(function (key) {
        return {
          label: "shareMemberRow.accessType.".concat(roles[key]),
          value: roles[key]
        };
      });
    }),
    noUpdate: Ember.computed('principal', 'principalId', function () {
      if (this.editing) {
        if (this.principal || this.principalId) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }),
    remove: function remove() {
      throw new Error('remove is a required action!');
    }
  });

  _exports.default = _default;
});