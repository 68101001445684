enifed("ember-template-compiler/lib/plugins/deprecate-send-action", ["exports", "@ember/debug", "@ember/deprecated-features", "ember-template-compiler/lib/system/calculate-location-display"], function (_exports, _debug, _deprecatedFeatures, _calculateLocationDisplay) {
  "use strict";

  _exports.default = deprecateSendAction;
  var EVENTS = ['insert-newline', 'enter', 'escape-press', 'focus-in', 'focus-out', 'key-press', 'key-up', 'key-down'];

  function deprecateSendAction(env) {
    if (_deprecatedFeatures.SEND_ACTION) {
      var {
        moduleName
      } = env.meta;

      var deprecationMessage = (node, eventName, actionName) => {
        var sourceInformation = (0, _calculateLocationDisplay.default)(moduleName, node.loc);

        if (true
        /* EMBER_GLIMMER_ANGLE_BRACKET_BUILT_INS */
        && node.type === 'ElementNode') {
          return "Passing actions to components as strings (like `<Input @" + eventName + "=\"" + actionName + "\" />`) is deprecated. Please use closure actions instead (`<Input @" + eventName + "={{action \"" + actionName + "\"}} />`). " + sourceInformation;
        } else {
          return "Passing actions to components as strings (like `{{input " + eventName + "=\"" + actionName + "\"}}`) is deprecated. Please use closure actions instead (`{{input " + eventName + "=(action \"" + actionName + "\")}}`). " + sourceInformation;
        }
      };

      return {
        name: 'deprecate-send-action',
        visitor: {
          ElementNode(node) {
            if (!true
            /* EMBER_GLIMMER_ANGLE_BRACKET_BUILT_INS */
            || node.tag !== 'Input') {
              return;
            }

            node.attributes.forEach(({
              name,
              value
            }) => {
              if (name.charAt(0) === '@') {
                var eventName = name.substring(1);

                if (EVENTS.indexOf(eventName) > -1) {
                  if (value.type === 'TextNode') {
                    true && !false && (0, _debug.deprecate)(deprecationMessage(node, eventName, value.chars), false, {
                      id: 'ember-component.send-action',
                      until: '4.0.0',
                      url: 'https://emberjs.com/deprecations/v3.x#toc_ember-component-send-action'
                    });
                  } else if (value.type === 'MustacheStatement' && value.path.type === 'StringLiteral') {
                    true && !false && (0, _debug.deprecate)(deprecationMessage(node, eventName, value.path.original), false, {
                      id: 'ember-component.send-action',
                      until: '4.0.0',
                      url: 'https://emberjs.com/deprecations/v3.x#toc_ember-component-send-action'
                    });
                  }
                }
              }
            });
          },

          MustacheStatement(node) {
            if (node.path.original !== 'input') {
              return;
            }

            node.hash.pairs.forEach(pair => {
              if (EVENTS.indexOf(pair.key) > -1 && pair.value.type === 'StringLiteral') {
                true && !false && (0, _debug.deprecate)(deprecationMessage(node, pair.key, pair.value.original), false, {
                  id: 'ember-component.send-action',
                  until: '4.0.0',
                  url: 'https://emberjs.com/deprecations/v3.x#toc_ember-component-send-action'
                });
              }
            });
          }

        }
      };
    }

    return;
  }
});