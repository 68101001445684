define("global-admin/clusters/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var headers = [{
    name: 'state',
    sort: ['sortState', 'name', 'id'],
    translationKey: 'generic.state',
    width: 125
  }, {
    name: 'name',
    searchField: 'displayName',
    sort: ['displayName', 'id'],
    translationKey: 'clustersPage.cluster.label'
  }, {
    name: 'provider',
    searchField: 'displayProvider',
    sort: ['displayProvider', 'name', 'id'],
    translationKey: 'clustersPage.provider.label',
    width: 150
  }, {
    name: 'nodes',
    searchField: 'nodes.length',
    sort: ['nodes.length', 'name', 'id'],
    translationKey: 'clustersPage.nodes.label',
    width: 100
  }, {
    name: 'cpu',
    sort: ['cpuUsage', 'name'],
    searchField: 'cpuUsage',
    translationKey: 'clustersPage.cpu.label',
    width: 100
  }, {
    name: 'memory',
    searchField: 'memoryUsage',
    sort: ['memoryUsage', 'name'],
    translationKey: 'clustersPage.memory.label',
    width: 125
  }, {
    name: 'explorer',
    searchField: false,
    sort: false,
    label: '',
    width: 100
  }];
  var NODE_SEARCH_FIELDS = ['displayName', 'externalIpAddress:ip', 'ipAddress:ip'];

  var _default = Ember.Controller.extend({
    modalService: Ember.inject.service('modal'),
    access: Ember.inject.service(),
    scope: Ember.inject.service(),
    settings: Ember.inject.service(),
    prefs: Ember.inject.service(),
    router: Ember.inject.service(),
    application: Ember.inject.controller(),
    queryParams: ['mode'],
    mode: 'grouped',
    headers: headers,
    extraSearchFields: ['version.gitVersion'],
    sortBy: 'name',
    searchText: null,
    bulkActions: true,
    extraSearchSubFields: NODE_SEARCH_FIELDS,
    actions: {
      launchOnCluster: function launchOnCluster(model) {
        var authenticated = Ember.getOwner(this).lookup('route:authenticated');

        if (this.get('scope.currentProject.id') === model.get('defaultProject.id')) {
          this.transitionToRoute('authenticated.host-templates', {
            queryParams: {
              clusterId: model.get('id'),
              backTo: 'clusters'
            }
          });
        } else {
          authenticated.send('switchProject', model.get('defaultProject.id'), 'authenticated.host-templates', [model.id, {
            queryParams: {
              backTo: 'clusters'
            }
          }]);
        }
      },
      useKubernetes: function useKubernetes(model) {
        var authenticated = Ember.getOwner(this).lookup('route:authenticated');
        authenticated.send('switchProject', model.get('defaultProject.id'), 'authenticated.cluster.import', [model.id, {
          queryParams: {
            backTo: 'clusters'
          }
        }]);
      }
    }
  });

  _exports.default = _default;
});