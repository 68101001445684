define("shared/mixins/cluster-driver", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    configField: '<override me>',
    mode: null,
    save: null,
    // Action to save
    close: null,
    // Action on complete
    saving: false,
    saved: false,
    globalStore: Ember.inject.service(),
    cluster: Ember.computed.alias('model.cluster'),
    primaryResource: Ember.computed.alias('model.cluster'),
    errors: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.defineProperty(this, 'config', Ember.computed('configField', "primaryResource.".concat(this.configField), function () {
        return Ember.get(_this, "cluster.".concat(Ember.get(_this, 'configField')));
      }));
    },
    actions: {
      errorHandler: function errorHandler(err) {
        var shouldClearPreviousErrors = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        var errors = this.errors;

        if (shouldClearPreviousErrors) {
          errors = Ember.set(this, 'errors', []);
        }

        if (errors) {
          if (Ember.isArray(err)) {
            errors.pushObjects(err);
          } else {
            errors.pushObject(err);
          }
        } else {
          errors = [err];
        }

        Ember.set(this, 'errors', errors);
      },
      driverSave: function driverSave(cb) {
        var _this2 = this;

        cb = cb || function () {};

        Ember.RSVP.resolve(this.willSave()).then(function (ok) {
          if (!ok) {
            // Validation or something else said not to save
            cb(false);
            return;
          }

          if (_this2.save) {
            _this2.save(function (ok) {
              if (ok) {
                _this2.doneSaving().finally(function () {
                  cb(ok);
                });
              } else {
                cb(ok);
              }
            });
          }
        });
      },
      setLabels: function setLabels(labels) {
        Ember.set(this, 'labels', labels);
        var out = {};
        labels.forEach(function (row) {
          out[row.key] = row.value;
        });
        this.set('labels', out);
      },
      registerHook: function registerHook() {
        var args = [].slice.call(arguments);
        args.unshift('registerHook');
        this.sendAction.apply(this, args);
      },
      close: function close() {
        if (this.close) {
          this.close(this.saved);
        }
      }
    },
    willSave: function willSave() {
      var cluster = this.cluster,
          field = this.configField;

      if (Ember.typeOf(cluster.clearProvidersExcept) === 'function') {
        cluster.clearProvidersExcept(field);
      }

      if (Ember.get(cluster, 'localClusterAuthEndpoint')) {
        if (!Ember.get(cluster, 'rancherKubernetesEngineConfig') || Ember.isEmpty(Ember.get(cluster, 'rancherKubernetesEngineConfig'))) {
          delete cluster.localClusterAuthEndpoint;
        }
      }

      Ember.set(this, 'errors', null);
      return this.validate();
    },
    validate: function validate() {
      var model = Ember.get(this, 'cluster');
      var errors = model.validationErrors(['appliedSpec']);
      Ember.set(this, 'errors', errors);
      return errors.length === 0;
    },
    doneSaving: function doneSaving() {
      return Ember.RSVP.resolve();
    }
  });

  _exports.default = _default;
});