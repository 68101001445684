enifed("ember-template-compiler/lib/plugins/assert-modifiers-not-in-components", ["exports", "@ember/debug", "ember-template-compiler/lib/system/calculate-location-display"], function (_exports, _debug, _calculateLocationDisplay) {
  "use strict";

  _exports.default = assertModifiersNotInComponents;

  function assertModifiersNotInComponents(env) {
    var {
      moduleName
    } = env.meta;
    var scopes = [];

    function isComponentInvocation(node) {
      return node.tag[0] === '@' || node.tag[0].toUpperCase() === node.tag[0] || node.tag.indexOf('.') > -1 || scopes.some(params => params.some(p => p === node.tag));
    }

    return {
      name: 'assert-modifiers-not-in-components',
      visitor: {
        Program: {
          enter(node) {
            scopes.push(node.blockParams);
          },

          exit() {
            scopes.pop();
          }

        },
        ElementNode: {
          keys: {
            children: {
              enter(node) {
                scopes.push(node.blockParams);
              },

              exit() {
                scopes.pop();
              }

            }
          },

          enter(node) {
            if (node.modifiers.length > 0 && isComponentInvocation(node)) {
              true && !false && (0, _debug.assert)("Passing modifiers to components require the \"ember-glimmer-forward-modifiers-with-splattributes\" canary feature, which has not been stabilized yet. See RFC #435 for details. " + (0, _calculateLocationDisplay.default)(moduleName, node.loc));
            }
          }

        }
      }
    };
  }
});