define("shared/components/tooltip-node-group-update/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "adB+sUpG",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[10,\"class\",\"tooltip-node-group-update\"],[8],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"mb-5\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"tooltipNodeGroupUpdate.label\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n    \"],[7,\"span\",false],[12,\"class\",\"text-link hand\"],[3,\"on\",[\"click\",[28,\"action\",[[23,0,[]],\"updateNodeGroup\"],null]]],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"tooltipNodeGroupUpdate.link\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/tooltip-node-group-update/template.hbs"
    }
  });

  _exports.default = _default;
});