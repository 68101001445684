enifed("ember-template-compiler/lib/plugins/transform-input-type-syntax", ["exports", "@glimmer/util"], function (_exports, _util) {
  "use strict";

  _exports.default = void 0;

  /**
   @module ember
  */

  /**
    A Glimmer2 AST transformation that replaces all instances of
  
    ```handlebars
   {{input type=boundType}}
    ```
  
    with
  
    ```handlebars
   {{input (-input-type boundType) type=boundType}}
    ```
  
    Note that the type parameters is not removed as the -input-type helpers
    is only used to select the component class. The component still needs
    the type parameter to function.
  
    @private
    @class TransformInputTypeSyntax
  */
  var transformInputTypeSyntax;

  if (true
  /* EMBER_GLIMMER_ANGLE_BRACKET_BUILT_INS */
  ) {
      transformInputTypeSyntax = () => {
        throw (0, _util.unreachable)();
      };
    } else {
    transformInputTypeSyntax = function transformInputTypeSyntax(env) {
      var b = env.syntax.builders;
      return {
        name: 'transform-input-type-syntax',
        visitor: {
          MustacheStatement(node) {
            if (isInput(node)) {
              insertTypeHelperParameter(node, b);
            }
          }

        }
      };
    };

    var isInput = function isInput(node) {
      return node.path.original === 'input';
    };

    var insertTypeHelperParameter = function insertTypeHelperParameter(node, builders) {
      var pairs = node.hash.pairs;
      var pair = null;

      for (var i = 0; i < pairs.length; i++) {
        if (pairs[i].key === 'type') {
          pair = pairs[i];
          break;
        }
      }

      if (pair && pair.value.type !== 'StringLiteral') {
        node.params.unshift(builders.sexpr('-input-type', [pair.value], undefined, pair.loc));
      }
    };
  }

  var _default = transformInputTypeSyntax;
  _exports.default = _default;
});