enifed("ember-template-compiler/tests/system/compile_options_test", ["ember-template-compiler/index", "internal-test-helpers"], function (_index, _internalTestHelpers) {
  "use strict";

  (0, _internalTestHelpers.moduleFor)('ember-template-compiler: default compile options', class extends _internalTestHelpers.AbstractTestCase {
    ['@test default options are a new copy'](assert) {
      assert.notEqual((0, _index.compileOptions)(), (0, _index.compileOptions)());
    }

    ['@test has default AST plugins'](assert) {
      assert.expect(_index.defaultPlugins.length);
      var plugins = (0, _index.compileOptions)().plugins.ast;

      for (var i = 0; i < _index.defaultPlugins.length; i++) {
        var plugin = _index.defaultPlugins[i];
        assert.ok(plugins.indexOf(plugin) > -1, "includes " + plugin);
      }
    }

  });
  var customTransformCounter = 0;

  class LegacyCustomTransform {
    constructor(options) {
      customTransformCounter++;
      this.options = options;
      this.syntax = null;
    }

    transform(ast) {
      var walker = new this.syntax.Walker();
      walker.visit(ast, node => {
        if (node.type !== 'ElementNode') {
          return;
        }

        for (var i = 0; i < node.attributes.length; i++) {
          var attribute = node.attributes[i];

          if (attribute.name === 'data-test') {
            node.attributes.splice(i, 1);
          }
        }
      });
      return ast;
    }

  }

  function customTransform() {
    customTransformCounter++;
    return {
      name: 'remove-data-test',
      visitor: {
        ElementNode(node) {
          for (var i = 0; i < node.attributes.length; i++) {
            var attribute = node.attributes[i];

            if (attribute.name === 'data-test') {
              node.attributes.splice(i, 1);
            }
          }
        }

      }
    };
  }

  class CustomPluginsTests extends _internalTestHelpers.RenderingTestCase {
    afterEach() {
      customTransformCounter = 0;
      return super.afterEach();
    }

    ['@test custom plugins can be used']() {
      this.render('<div data-test="foo" data-blah="derp" class="hahaha"></div>');
      this.assertElement(this.firstChild, {
        tagName: 'div',
        attrs: {
          class: 'hahaha',
          'data-blah': 'derp'
        },
        content: ''
      });
    }

    ['@test wrapped plugins are only invoked once per template'](assert) {
      this.render('<div>{{#if falsey}}nope{{/if}}</div>');
      assert.equal(customTransformCounter, 1, 'transform should only be instantiated once');
    }

  }

  (0, _internalTestHelpers.moduleFor)('ember-template-compiler: registerPlugin with a custom plugins in legacy format', class extends CustomPluginsTests {
    beforeEach() {
      (0, _index.registerPlugin)('ast', LegacyCustomTransform);
    }

    afterEach() {
      (0, _index.unregisterPlugin)('ast', LegacyCustomTransform);
      return super.afterEach();
    }

    ['@test custom registered plugins are deduplicated'](assert) {
      (0, _index.registerPlugin)('ast', LegacyCustomTransform);
      this.registerTemplate('application', '<div data-test="foo" data-blah="derp" class="hahaha"></div>');
      assert.equal(customTransformCounter, 1, 'transform should only be instantiated once');
    }

  });
  (0, _internalTestHelpers.moduleFor)('ember-template-compiler: registerPlugin with a custom plugins', class extends CustomPluginsTests {
    beforeEach() {
      (0, _index.registerPlugin)('ast', customTransform);
    }

    afterEach() {
      (0, _index.unregisterPlugin)('ast', customTransform);
      return super.afterEach();
    }

    ['@test custom registered plugins are deduplicated'](assert) {
      (0, _index.registerPlugin)('ast', customTransform);
      this.registerTemplate('application', '<div data-test="foo" data-blah="derp" class="hahaha"></div>');
      assert.equal(customTransformCounter, 1, 'transform should only be instantiated once');
    }

  });
  (0, _internalTestHelpers.moduleFor)('ember-template-compiler: custom plugins in legacy format passed to compile', class extends _internalTestHelpers.RenderingTestCase {
    // override so that we can provide custom AST plugins to compile
    compile(templateString) {
      return (0, _index.compile)(templateString, {
        plugins: {
          ast: [LegacyCustomTransform]
        }
      });
    }

  });
  (0, _internalTestHelpers.moduleFor)('ember-template-compiler: custom plugins passed to compile', class extends _internalTestHelpers.RenderingTestCase {
    // override so that we can provide custom AST plugins to compile
    compile(templateString) {
      return (0, _index.compile)(templateString, {
        plugins: {
          ast: [customTransform]
        }
      });
    }

  });
});