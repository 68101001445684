define("shared/components/cluster-driver/driver-azureaks/component", ["exports", "shared/mixins/cluster-driver", "shared/components/cluster-driver/driver-azureaks/template", "ipaddr.js", "shared/utils/constants", "semver", "ui/utils/azure-choices"], function (_exports, _clusterDriver, _template, _ipaddr, _constants, _semver, _azureChoices) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var NETWORK_POLICY = ['calico'];
  var CHINA_REGION_API_URL = 'https://management.chinacloudapi.cn/';
  var CHINA_REGION_AUTH_URL = 'https://login.chinacloudapi.cn/';
  var NETWORK_PLUGINS = [{
    label: 'Kubenet',
    value: 'kubenet'
  }, {
    label: 'Azure',
    value: 'azure'
  }];

  var _default = Ember.Component.extend(_clusterDriver.default, {
    globalStore: Ember.inject.service(),
    intl: Ember.inject.service(),
    settings: Ember.inject.service(),
    versionChoiceService: Ember.inject.service('version-choices'),
    layout: _template.default,
    configField: 'azureKubernetesServiceConfig',
    zones: _azureChoices.aksRegions,
    versions: null,
    machineSizes: _azureChoices.sizes,
    step: 1,
    netMode: 'default',
    monitoringRegionConent: [],
    networkPlugins: NETWORK_PLUGINS,
    defaultK8sVersionRange: Ember.computed.alias("settings.".concat(_constants.default.SETTING.VERSION_SYSTEM_K8S_DEFAULT_RANGE)),
    editing: Ember.computed.equal('mode', 'edit'),
    isNew: Ember.computed.equal('mode', 'new'),
    init: function init() {
      this._super.apply(this, arguments);

      var config = Ember.get(this, 'cluster.azureKubernetesServiceConfig');

      if (!config) {
        config = this.get('globalStore').createRecord({
          agentPoolName: 'rancher',
          type: 'azureKubernetesServiceConfig',
          agentOsdiskSize: 100,
          adminUsername: 'azureuser',
          count: 3,
          agentVmSize: 'Standard_D2_v2',
          location: 'eastus',
          enableHttpApplicationRouting: false,
          enableMonitoring: true
        });
        Ember.set(this, 'cluster.azureKubernetesServiceConfig', config);
      } else {
        var tags = Ember.get(config, 'tags') || [];
        var map = {};
        tags.map(function () {
          var t = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
          var split = t.split('=');
          Ember.set(map, split[0], split[1]);
        });
        Ember.set(this, 'tags', map);

        if (Ember.get(config, 'networkPolicy') || Ember.get(config, 'subnet')) {
          Ember.set(this, 'netMode', 'advanced');
        }
      }
    },
    actions: {
      authenticate: function authenticate(cb) {
        var _this = this;

        var store = Ember.get(this, 'globalStore');
        var data = {
          clientId: Ember.get(this, 'config.clientId'),
          clientSecret: Ember.get(this, 'config.clientSecret'),
          subscriptionId: Ember.get(this, 'config.subscriptionId'),
          tenantId: Ember.get(this, 'config.tenantId'),
          region: Ember.get(this, 'config.location')
        };

        if (Ember.get(this, 'isChinaRegion')) {
          Ember.setProperties(data, {
            baseUrl: CHINA_REGION_API_URL,
            authBaseUrl: CHINA_REGION_AUTH_URL
          });
        }

        var aksRequest = {
          versions: store.rawRequest({
            url: '/meta/aksVersions',
            method: 'POST',
            data: data
          }),
          virtualNetworks: store.rawRequest({
            url: '/meta/aksVirtualNetworks',
            method: 'POST',
            data: data
          })
        };
        return Ember.RSVP.hash(aksRequest).then(function (resp) {
          var mode = _this.mode;
          var versions = resp.versions,
              virtualNetworks = resp.virtualNetworks;
          var isEdit = mode === 'edit';
          var versionz = Ember.get(versions, 'body') || [];
          var initialVersion = isEdit ? _this.config.kubernetesVersion : _semver.default.maxSatisfying(versionz, _this.defaultK8sVersionRange);

          if (!isEdit && initialVersion) {
            Ember.set(_this, 'cluster.azureKubernetesServiceConfig.kubernetesVersion', initialVersion);
          }

          Ember.setProperties(_this, {
            step: 2,
            versions: Ember.get(versions, 'body') || [],
            virtualNetworks: Ember.get(virtualNetworks, 'body') || []
          });
          cb(true);
        }).catch(function (xhr) {
          var err = xhr.body.message || xhr.body.code || xhr.body.error;
          Ember.setProperties(_this, {
            errors: [err]
          });
          cb(false, [err]);
        });
      },
      setTags: function setTags(section) {
        var out = [];

        for (var key in section) {
          out.pushObject("".concat(key, "=").concat(section[key]));
        }

        Ember.set(this, 'config.tags', out);
      }
    },
    resetAdvancedOptions: Ember.on('init', Ember.observer('netMode', function () {
      if (Ember.get(this, 'isNew') && Ember.get(this, 'netMode') === 'default') {
        var config = Ember.get(this, 'config');

        var _this$globalStore$get = this.globalStore.getById('schema', 'azurekubernetesserviceconfig').getCreateDefaults(),
            subnet = _this$globalStore$get.subnet,
            virtualNetwork = _this$globalStore$get.virtualNetwork,
            virtualNetworkResourceGroup = _this$globalStore$get.virtualNetworkResourceGroup,
            serviceCidr = _this$globalStore$get.serviceCidr,
            dnsServiceIp = _this$globalStore$get.dnsServiceIp,
            dockerBridgeCidr = _this$globalStore$get.dockerBridgeCidr;

        Ember.setProperties(config, {
          subnet: subnet,
          virtualNetwork: virtualNetwork,
          virtualNetworkResourceGroup: virtualNetworkResourceGroup,
          serviceCidr: serviceCidr,
          dnsServiceIp: dnsServiceIp,
          dockerBridgeCidr: dockerBridgeCidr
        });
      }
    })),
    versionChoices: Ember.computed('versions', function () {
      var _this$versions = this.versions,
          versions = _this$versions === void 0 ? [] : _this$versions,
          mode = this.mode,
          initialVersion = this.config.kubernetesVersion; // azure versions come in oldest to newest

      return this.versionChoiceService.parseCloudProviderVersionChoices((versions || []).reverse(), initialVersion, mode);
    }),
    networkChoice: Ember.computed({
      set: function set(key) {
        var value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

        var _value$split = value.split(':'),
            _value$split2 = _slicedToArray(_value$split, 3),
            subnet = _value$split2[0],
            virtualNetwork = _value$split2[1],
            virtualNetworkResourceGroup = _value$split2[2];

        var config = Ember.get(this, 'config');

        if (subnet && virtualNetwork && virtualNetworkResourceGroup) {
          Ember.setProperties(config, {
            subnet: subnet,
            virtualNetwork: virtualNetwork,
            virtualNetworkResourceGroup: virtualNetworkResourceGroup
          });
        }

        return value;
      }
    }),
    filteredVirtualNetworks: Ember.computed('config.virtualNetwork', 'virtualNetworks', function () {
      var vnets = Ember.get(this, 'virtualNetworks') || [];
      var subNets = [];
      vnets.forEach(function (vnet) {
        (Ember.get(vnet, 'subnets') || []).forEach(function (subnet) {
          subNets.pushObject({
            name: "".concat(Ember.get(subnet, 'name'), " (").concat(Ember.get(subnet, 'addressRange'), ")"),
            group: Ember.get(vnet, 'name'),
            value: "".concat(Ember.get(subnet, 'name'), ":").concat(Ember.get(vnet, 'name'), ":").concat(Ember.get(vnet, 'resourceGroup'))
          });
        });
      });
      return subNets;
    }),
    networkChoiceDisplay: Ember.computed('virtualNetworks', 'config.virtualNetwork', 'config.subnet', function () {
      var selected = (Ember.get(this, 'virtualNetworks') || []).findBy('name', Ember.get(this, 'config.virtualNetwork')) || {};
      var subnet = (Ember.get(selected, 'subnets') || []).findBy('name', Ember.get(this, 'config.subnet')) || {};
      return "".concat(Ember.get(subnet, 'name'), " (").concat(Ember.get(subnet, 'addressRange'), ")");
    }),
    isEditable: Ember.computed('mode', function () {
      return Ember.get(this, 'mode') === 'edit' || Ember.get(this, 'mode') === 'new' ? true : false;
    }),
    isChinaRegion: Ember.computed('config.location', function () {
      return Ember.get(this, 'config.location').startsWith('china');
    }),
    saveDisabled: Ember.computed('config.subscriptionId', 'config.tenantId', 'config.clientId', 'config.clientSecret', 'config.location', function () {
      return Ember.get(this, 'config.tenantId') && Ember.get(this, 'config.clientId') && Ember.get(this, 'config.clientSecret') && Ember.get(this, 'config.subscriptionId') && Ember.get(this, 'config.location') ? false : true;
    }),
    networkPolicyContent: Ember.computed(function () {
      return NETWORK_POLICY.map(function (n) {
        return {
          label: n,
          value: n
        };
      });
    }),
    validate: function validate() {
      var intl = Ember.get(this, 'intl');
      var model = Ember.get(this, 'cluster');
      var errors = model.validationErrors() || [];
      var vnetSet = !!Ember.get(this, 'config.virtualNetwork');

      if (vnetSet) {
        errors = errors.concat(this.validateVnetInputs());
      }

      if (!Ember.get(this, 'config.resourceGroup')) {
        errors.push(intl.t('validation.required', {
          key: intl.t('clusterNew.azureaks.resourceGroup.label')
        }));
      }

      if (!Ember.get(this, 'config.sshPublicKeyContents')) {
        errors.push(intl.t('validation.required', {
          key: intl.t('clusterNew.azureaks.ssh.label')
        }));
      }

      Ember.set(this, 'errors', errors);
      return errors.length === 0;
    },
    validateVnetInputs: function validateVnetInputs() {
      var intl = Ember.get(this, 'intl');
      var errors = [];
      var config = Ember.get(this, 'config');
      var vnet = Ember.get(this, 'virtualNetworks').findBy('name', Ember.get(config, 'virtualNetwork'));

      if (vnet) {
        var subnet = Ember.get(vnet, "subnets").findBy('name', Ember.get(config, 'subnet'));

        var vnetRange = _ipaddr.default.parseCIDR(Ember.get(subnet, 'addressRange'));

        var serviceCidr = config.serviceCidr,
            dnsServiceIp = config.dnsServiceIp,
            dockerBridgeCidr = config.dockerBridgeCidr;
        var parsedServiceCidr = null;
        var parsedDnsServiceIp = null;
        var parsedDockerBridgeCidr = null;

        if (!serviceCidr && !dnsServiceIp && !dockerBridgeCidr) {
          errors.pushObject('You must include all required fields when using a Virtual Network');
        }

        try {
          parsedServiceCidr = _ipaddr.default.parseCIDR(serviceCidr); // check if serviceCidr falls within the VNet/Subnet range

          if (parsedServiceCidr && vnetRange[0].match(parsedServiceCidr)) {
            errors.pushObject(intl.t('clusterNew.azureaks.errors.included.parsedServiceCidr'));
          }
        } catch (err) {
          errors.pushObject(intl.t('clusterNew.azureaks.errors.included.serviceCidr'));
        }

        try {
          parsedDnsServiceIp = _ipaddr.default.parse(dnsServiceIp); // check if dnsService exists in range

          if (parsedDnsServiceIp && vnetRange[0].match(parsedDnsServiceIp, vnetRange[1])) {
            errors.pushObject(intl.t('clusterNew.azureaks.errors.included.parsedDnsServiceIp'));
          }
        } catch (err) {
          errors.pushObject(intl.t('clusterNew.azureaks.errors.included.dnsServiceIp'));
        }

        try {
          parsedDockerBridgeCidr = _ipaddr.default.parseCIDR(dockerBridgeCidr); // check that dockerBridge doesn't overlap

          if (parsedDockerBridgeCidr && (vnetRange[0].match(parsedDockerBridgeCidr) || parsedServiceCidr[0].match(parsedDockerBridgeCidr))) {
            errors.pushObject(intl.t('clusterNew.azureaks.errors.included.parsedDockerBridgeCidr'));
          }
        } catch (err) {
          errors.pushObject(intl.t('clusterNew.azureaks.errors.included.dockerBridgeCidr'));
        }
      }

      return errors;
    },
    willSave: function willSave() {
      var enableMonitoring = Ember.get(this, 'config.enableMonitoring');
      var config = Ember.get(this, 'config');

      if (enableMonitoring) {
        Ember.setProperties(config, {
          logAnalyticsWorkspaceResourceGroup: '',
          logAnalyticsWorkspace: ''
        });
      } else {
        Ember.setProperties(config, {
          logAnalyticsWorkspaceResourceGroup: null,
          logAnalyticsWorkspace: null
        });
      }

      if (Ember.get(this, 'isChinaRegion')) {
        Ember.setProperties(config, {
          baseUrl: CHINA_REGION_API_URL,
          authBaseUrl: CHINA_REGION_AUTH_URL
        });
      } else {
        delete config['baseUrl'];
        delete config['authBaseUrl'];
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});