enifed("ember-template-compiler/lib/plugins/transform-old-class-binding-syntax", ["exports"], function (_exports) {
  "use strict";

  _exports.default = transformOldClassBindingSyntax;

  function transformOldClassBindingSyntax(env) {
    var b = env.syntax.builders;
    return {
      name: 'transform-old-class-binding-syntax',
      visitor: {
        MustacheStatement(node) {
          process(b, node);
        },

        BlockStatement(node) {
          process(b, node);
        }

      }
    };
  }

  function process(b, node) {
    var allOfTheMicrosyntaxes = [];
    var allOfTheMicrosyntaxIndexes = [];
    var classPair;
    each(node.hash.pairs, (pair, index) => {
      var {
        key
      } = pair;

      if (key === 'classBinding' || key === 'classNameBindings') {
        allOfTheMicrosyntaxIndexes.push(index);
        allOfTheMicrosyntaxes.push(pair);
      } else if (key === 'class') {
        classPair = pair;
      }
    });

    if (allOfTheMicrosyntaxes.length === 0) {
      return;
    }

    var classValue = [];

    if (classPair) {
      classValue.push(classPair.value);
      classValue.push(b.string(' '));
    } else {
      classPair = b.pair('class', null);
      node.hash.pairs.push(classPair);
    }

    each(allOfTheMicrosyntaxIndexes, index => {
      node.hash.pairs.splice(index, 1);
    });
    each(allOfTheMicrosyntaxes, ({
      value
    }) => {
      var sexprs = []; // TODO: add helpful deprecation when both `classNames` and `classNameBindings` can
      // be removed.

      if (value.type === 'StringLiteral') {
        var microsyntax = parseMicrosyntax(value.original);
        buildSexprs(microsyntax, sexprs, b);
        classValue.push(...sexprs);
      }
    });
    var hash = b.hash();
    classPair.value = b.sexpr(b.path('concat'), classValue, hash);
  }

  function buildSexprs(microsyntax, sexprs, b) {
    for (var i = 0; i < microsyntax.length; i++) {
      var [propName, activeClass, inactiveClass] = microsyntax[i];
      var sexpr = void 0; // :my-class-name microsyntax for static values

      if (propName === '') {
        sexpr = b.string(activeClass);
      } else {
        var params = [b.path(propName)];

        if (activeClass || activeClass === '') {
          params.push(b.string(activeClass));
        } else {
          var sexprParams = [b.string(propName), b.path(propName)];
          var hash = b.hash();

          if (activeClass !== undefined) {
            hash.pairs.push(b.pair('activeClass', b.string(activeClass)));
          }

          if (inactiveClass !== undefined) {
            hash.pairs.push(b.pair('inactiveClass', b.string(inactiveClass)));
          }

          params.push(b.sexpr(b.path('-normalize-class'), sexprParams, hash));
        }

        if (inactiveClass || inactiveClass === '') {
          params.push(b.string(inactiveClass));
        }

        sexpr = b.sexpr(b.path('if'), params);
      }

      sexprs.push(sexpr);
      sexprs.push(b.string(' '));
    }
  }

  function each(list, callback) {
    for (var i = 0; i < list.length; i++) {
      callback(list[i], i);
    }
  }

  function parseMicrosyntax(string) {
    var segments = string.split(' ');
    var ret = [];

    for (var i = 0; i < segments.length; i++) {
      ret[i] = segments[i].split(':');
    }

    return ret;
  }
});