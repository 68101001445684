define("shared/scope/service", ["exports", "shared/utils/subscribe-global", "shared/utils/subscribe-cluster", "shared/utils/subscribe-project"], function (_exports, _subscribeGlobal, _subscribeCluster, _subscribeProject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    growl: Ember.inject.service(),
    prefs: Ember.inject.service(),
    settings: Ember.inject.service(),
    store: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    cookies: Ember.inject.service(),
    app: Ember.inject.service(),
    currentProject: null,
    currentCluster: null,
    currentPageScope: 'none',
    docsBase: Ember.computed.alias('settings.docsBase'),
    allProjects: null,
    allClusters: null,
    init: function init() {
      var globalStore = Ember.get(this, 'globalStore');

      this._super.apply(this, arguments);

      Ember.setProperties(this, {
        allClusters: globalStore.all('cluster'),
        allProjects: globalStore.all('project')
      });
      this.initSubscribe();
    },
    // Subscribe
    subscribeGlobal: null,
    subscribeCluster: null,
    subscribeProject: null,
    initSubscribe: function initSubscribe() {
      var deps = {
        app: Ember.get(this, 'app'),
        store: Ember.get(this, 'store'),
        clusterStore: Ember.get(this, 'clusterStore'),
        globalStore: Ember.get(this, 'globalStore'),
        intl: Ember.get(this, 'intl'),
        growl: Ember.get(this, 'growl'),
        scope: this
      };

      var g = _subscribeGlobal.default.create(deps);

      var c = _subscribeCluster.default.create(deps);

      var p = _subscribeProject.default.create(deps);

      g.set('label', 'Global');
      g.set('label', 'Cluster');
      p.set('label', 'Project');
      Ember.setProperties(this, {
        subscribeGlobal: g,
        subscribeCluster: c,
        subscribeProject: p
      });
    },
    // End: Subscribe
    startSwitchToNothing: function startSwitchToNothing() {
      var _this = this;

      this._setPageScope('global');

      return Ember.get(this, 'subscribeGlobal').disconnect().then(function () {
        return _this._startSwitchTo(null, null).then(function () {
          Ember.get(_this, 'globalStore').reset();
        });
      });
    },
    finishSwitchToNothing: function finishSwitchToNothing() {
      this._finishSwitchTo(null, null);
    },
    startSwitchToGlobal: function startSwitchToGlobal() {
      var connect = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

      this._setPageScope('global');

      if (connect) {
        Ember.get(this, 'subscribeGlobal').connect(false);
      }

      return this._startSwitchTo(null, null, connect);
    },
    finishSwitchToGlobal: function finishSwitchToGlobal() {
      return this._finishSwitchTo(null, null);
    },
    startSwitchToCluster: function startSwitchToCluster(cluster) {
      var connect = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      this._setPageScope('cluster');

      return this._startSwitchTo(cluster, null, connect);
    },
    finishSwitchToCluster: function finishSwitchToCluster(cluster) {
      return this._finishSwitchTo(cluster, null);
    },
    startSwitchToProject: function startSwitchToProject(project) {
      var connect = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      this._setPageScope('project');

      return this._startSwitchTo(Ember.get(project, 'cluster'), project, connect);
    },
    finishSwitchToProject: function finishSwitchToProject(project) {
      return this._finishSwitchTo(Ember.get(project, 'cluster'), project);
    },
    _setPageScope: function _setPageScope(scope) {
      Ember.set(this, 'currentPageScope', scope);
    },
    _startSwitchTo: function _startSwitchTo(cluster, project) {
      var connect = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      var clusterOld = Ember.get(this, 'currentCluster');
      var clusterSubscribe = Ember.get(this, 'subscribeCluster');
      var clusterStore = Ember.get(this, 'clusterStore');
      var clusterId = cluster && Ember.get(cluster, 'id') || null;
      var clusterReset = false;
      var projectOld = Ember.get(this, 'currentProject');
      var projectSubscribe = Ember.get(this, 'subscribeProject');
      var projectStore = Ember.get(this, 'store');
      var projectId = project && Ember.get(project, 'id') || null;
      var projectReset = false;
      var cleanupPromises = [];
      Ember.set(this, 'pendingCluster', cluster);
      Ember.set(this, 'pendingProject', project);

      if (cluster !== clusterOld) {
        if (cluster) {
          Ember.set(clusterStore, 'baseUrl', "".concat(Ember.get(this, 'app.apiEndpoint'), "/clusters/").concat(clusterId));
        }

        cleanupPromises.push(clusterSubscribe.disconnect());
        clusterReset = true;
      }

      if (!Ember.get(clusterSubscribe, 'wasConnected')) {
        clusterReset = true;
      }

      if (project !== projectOld) {
        if (project) {
          Ember.set(projectStore, 'baseUrl', "".concat(Ember.get(this, 'app.apiEndpoint'), "/projects/").concat(projectId));
        }

        cleanupPromises.push(projectSubscribe.disconnect());
        projectReset = true;
      }

      return Ember.RSVP.all(cleanupPromises).then(function () {
        if (clusterReset) {
          clusterStore.reset();

          if (cluster && connect) {
            clusterSubscribe.connect(true, clusterId, projectId);
          }
        }

        if (projectReset) {
          projectStore.reset();

          if (project && connect) {
            projectSubscribe.connect(true, clusterId, projectId);
          }
        }
      });
    },
    _finishSwitchTo: function _finishSwitchTo(cluster, project) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve) {
        Ember.setProperties(_this2, {
          currentCluster: cluster,
          currentProject: project
        });
        resolve();
        return;
      });
    },
    getAllProjects: function getAllProjects() {
      var moreOpt = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var opt = {
        url: 'projects'
      }; // This is called in authenticated/route before schemas are loaded

      Object.assign(opt, moreOpt);
      return Ember.get(this, 'globalStore').findAll('project', opt);
    },
    getAllClusters: function getAllClusters() {
      var moreOpt = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var opt = {
        url: 'clusters'
      }; // This is called in authenticated/route before schemas are loaded

      Object.assign(opt, moreOpt);
      return Ember.get(this, 'globalStore').findAll('cluster', opt);
    },
    dashboardBase: Ember.computed('currentCluster.id', function () {
      var link;

      if (Ember.get(this, 'app.environment') === 'development') {
        link = 'https://localhost:8005/';
      } else {
        link = '/dashboard/';
      }

      return link;
    }),
    dashboardLink: Ember.computed('dashboardBase', 'currentCluster.id', function () {
      var cluster = Ember.get(this, 'currentCluster');

      if (!cluster || !cluster.isReady) {
        // Only in ready/active clusters
        return;
      }

      var link = Ember.get(this, 'dashboardBase');

      if (link) {
        return "".concat(link, "c/").concat(escape(cluster.id));
      }
    })
  });

  _exports.default = _default;
});