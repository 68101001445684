enifed("ember-template-compiler/tests/plugins/deprecate-send-action-test", ["ember-template-compiler/index", "internal-test-helpers"], function (_index, _internalTestHelpers) {
  "use strict";

  var EVENTS = ['insert-newline', 'enter', 'escape-press', 'focus-in', 'focus-out', 'key-press', 'key-up', 'key-down'];

  class DeprecateSendActionTest extends _internalTestHelpers.AbstractTestCase {}

  EVENTS.forEach(function (e) {
    DeprecateSendActionTest.prototype["@test Using `{{input " + e + "=\"actionName\"}}` provides a deprecation"] = function () {
      var expectedMessage = "Passing actions to components as strings (like `{{input " + e + "=\"foo-bar\"}}`) is deprecated. Please use closure actions instead (`{{input " + e + "=(action \"foo-bar\")}}`). ('baz/foo-bar' @ L1:C0) ";
      expectDeprecation(() => {
        (0, _index.compile)("{{input " + e + "=\"foo-bar\"}}", {
          moduleName: 'baz/foo-bar'
        });
      }, expectedMessage);
    };
  });
  (0, _internalTestHelpers.moduleFor)('ember-template-compiler: deprecate-send-action', DeprecateSendActionTest);
});